<template>
  <div class="mt-1 mx-3">
    <titles>
    </titles>
  </div>
</template>

<script>
import Titles from '@/components/insurance-ancillaries/create.vue';
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    Titles,
  },
  created() {
    this.notifyQueue({ text: "Crea Titolo", path: "insurance-ancillaries.create", level: 3 });
  }
}
</script>