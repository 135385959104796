<template>
  <div class="mt-1 mx-3">
    <titles> </titles>
  </div>
</template>

<script>
import Titles from "@/components/insurance-ancillaries/index.vue";
import { mapActions } from "vuex";

export default {
  props: {},
  components: {
    Titles,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  created() {
    this.notifyQueue({ text: "Titoli", path: "", level: 1 });
    this.notifyQueue({
      text: "Ricerca titoli o insoluti",
      path: "module.TIT.TINS",
      level: 2,
    });
  },
};
</script>
