<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <b-card header="Dati Identificativi" header-tag="header">
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <div>
                        <b-button-toolbar>
                          <b-button-group>
                            <base-input
                              :name="beForm[rep].insurance_policy_id.label"
                              :label="beForm[rep].insurance_policy_id.label"
                              v-model="form.inpt_label"
                              :rules="getRules('insurance_policy_id')"
                              :readonly="true"
                            />
                            <b-button
                              class="mt-4 btn-quick"
                              size="sm"
                              text="Button"
                              variant="lisaweb"
                              title="Ricerca Veloce"
                              @click="openQuickSearchPolicy"
                              ><b-icon icon="search"></b-icon
                            ></b-button>
                          </b-button-group>
                        </b-button-toolbar>
                      </div>
                    </div>
                    <div class="form-group col-md-3">
                      <span
                        v-if="insurance_policy_data"
                        class="info"
                        v-html="
                          toInfoData(
                            insurance_policy_data,
                            'insurance_policy',
                            0
                          )
                        "
                      >
                      </span>
                    </div>
                    <quick-search-policy-modal
                      ref="quickSearchPolicy"
                      @input="handlePolicyModalInput"
                    ></quick-search-policy-modal>
                  </b-row>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-datepicker
                        vid="effective_at"
                        :name="beForm[rep].effective_at.label"
                        :label="beForm[rep].effective_at.label"
                        v-model="form[rep].effective_at"
                        :rules="getRules('effective_at')"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        vid="delay_days"
                        :name="beForm[rep].delay_days.label"
                        :label="beForm[rep].delay_days.label"
                        v-model="form[rep].delay_days"
                        :options="beForm[rep].delay_days.options"
                        :rules="getRules('delay_days')"
                        disabled
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-select
                        :name="beForm[rep].broker_id.label"
                        vid="broker_id"
                        :label="beForm[rep].broker_id.label"
                        v-model="form[rep].broker_id"
                        :options="broker_options"
                        :rules="getRules('broker_id')"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>
              <div class="mt-2">
                <b-card header="Premio" header-tag="header">
                  <div @click="onClickSwitch()" class="switch">
                    <!-- Disabilitato momentaneamnete utilizzo degli scorproi , story-504 -->
                    <!--
                      !form[rep].insurance_policy_id ||
                        breakdowns.length == 0 ||
                        isLoadingBreakdown
                      -->
                    <b-form-checkbox
                      v-model="isBreakdown"
                      name="check-button"
                      switch
                      :disabled="true"
                      @change="onChangeSwitch()"
                    >
                      {{ isBreakdown ? "Scorpori Premio" : "Premio" }}
                    </b-form-checkbox>
                  </div>
                  <b-card-text v-if="!isBreakdown">
                    <b-row>
                      <div class="form-group col-md-3">
                        <base-currency
                          :name="beForm[rep].net.label"
                          vid="net"
                          :label="beForm[rep].net.label"
                          v-model="form[rep].net"
                          :options="{
                            currency: 'EUR',
                            locale: 'it-IT',
                            precision: 2,
                          }"
                          :rules="getRules('net')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-currency
                          :name="beForm[rep].tax.label"
                          vid="tax_premium"
                          :label="beForm[rep].tax.label"
                          v-model="form[rep].tax"
                          :options="{
                            currency: 'EUR',
                            locale: 'it-IT',
                            precision: 2,
                          }"
                          :rules="getRules('tax')"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-input
                          :name="beForm[rep].gross.label"
                          vid="gross"
                          :label="beForm[rep].gross.label"
                          v-model="grossPremiumSum"
                          :readonly="true"
                          custom_type="currency"
                          :rules="getRules('gross')"
                        />
                      </div>
                    </b-row>
                  </b-card-text>
                  <b-card-text v-if="isBreakdown">
                    <div v-if="breakdowns.length">
                      <b-overlay center :show="isLoadingBreakdown" rounded="sm">
                        <b-table
                          hover
                          :items="breakdowns"
                          :fields="fields"
                          ref="breakdowns"
                        >
                          <template v-slot:cell(net)="row">
                            <div class="form-group col-md-7">
                              <base-currency
                                :name="beForm[rep].net.label"
                                :vid="`net-${row.item.id}`"
                                :label="beForm[rep].net.label"
                                v-model="row.item.net.value"
                                :options="{
                                  currency: 'EUR',
                                  locale: 'it-IT',
                                  precision: 2,
                                }"
                                :readonly="
                                  row.item.net.is_net === 'Y' ? false : true
                                "
                              />
                            </div>
                          </template>
                          <template v-slot:cell(tax)="row">
                            <div class="form-group col-md-7">
                              <base-currency
                                name="Tasse"
                                :vid="`tax-${row.item.id}`"
                                label="Tasse"
                                v-model="row.item.tax.value"
                                :options="{
                                  currency: 'EUR',
                                  locale: 'it-IT',
                                  precision: 2,
                                }"
                                :readonly="
                                  row.item.tax.is_tax === 'Y' ? false : true
                                "
                              />
                            </div>
                          </template>
                          <template v-slot:cell(grossrow)="row">
                            <div class="form-group col-md-7">
                              <base-input
                                name="grossrow"
                                :vid="`grossrow-${row.item.id}`"
                                label="Lordo Riga"
                                custom_type="currency"
                                :value="
                                  grossRowBookEntry(
                                    row.item.tax.value,
                                    row.item.net.value
                                  )
                                "
                                :readonly="true"
                              />
                            </div>
                          </template>
                          <template slot="bottom-row">
                            <td>Totale</td>
                            <td>
                              <div class="form-group col-md-7">
                                <base-label
                                  class="net-sum-label"
                                  :value="netSum"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-group col-md-7">
                                <base-label
                                  class="tax-sum-label"
                                  :value="taxSum"
                                />
                              </div>
                            </td>
                            <td>
                              <div class="form-group col-md-7">
                                <base-label
                                  class="gross-sum-label"
                                  :value="grossSum"
                                />
                              </div>
                            </td>
                          </template>
                        </b-table>
                        <template #overlay>
                          <div class="text-center">
                            <base-icon name="loading" width="35" height="35" />
                            <p id="cancel-label">Operazione in corso...</p>
                          </div>
                        </template>
                      </b-overlay>
                    </div>
                  </b-card-text>
                  <!-- Breakdownables -->
                </b-card>
              </div>

              <b-card
                v-if="agencyHasFee"
                header="Diritti"
                header-tag="header"
                class="mt-1"
              >
                <b-card-text>
                  <b-row>
                    <div class="form-group col-md-3">
                      <base-currency
                        name="fee_net"
                        vid="fee_net"
                        label="Netto"
                        v-model="form[rep].fee_net"
                        :readonly="!insurerHasFee"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        name="fee_tax"
                        vid="fee_tax"
                        label="Tasse"
                        v-model="form[rep].fee_tax"
                        :readonly="!insurerHasFee"
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                      />
                    </div>
                    <div class="form-group col-md-3">
                      <base-currency
                        name="fee_gross"
                        vid="fee_gross"
                        label="Lordo"
                        v-model="grossSumFee"
                        readonly
                        :options="{
                          currency: 'EUR',
                          locale: 'it-IT',
                          precision: 2,
                        }"
                      />
                    </div>
                  </b-row>
                </b-card-text>
              </b-card>

              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <custom-inputs
                      :customInputs="customInputs[rep]"
                      v-model="form[rep]"
                      :beForm="beForm[rep]"
                      :beRules="beRules[rep]"
                    />
                  </b-card-text>
                </b-card>
              </div>

              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button @click="resetForm()" variant="lisaweb" size="sm"
                  >Reset</b-button
                >
                <b-button
                  @click="$router.back()"
                  variant="outline-lisaweb"
                  size="sm"
                  class="btn-reset float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import BaseInput from "@/components/form/BaseInput";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseSelect from "@/components/form/BaseSelect";
import BaseLabel from "@/components/form/BaseLabel";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import FormMixin from "@/mixins/FormMixin";
import CustomInputs from "@/components/form/CustomInputs";
import BaseCurrency from "@/components/form/BaseCurrency";
import QuickSearchPolicyModal from "@/components/modals/quickSearchPolicy";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { toLocaleCurrency } from "@/utils/strings";
import { toInfoData } from "@/utils/transforms";
import { sumBreakdowns, dataMappingPremium } from "@/utils/breakdowns";
import { mapGetters } from "vuex";
import moment from "moment";
moment.locale("it");
export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "insurance_ancillary",
      id: this.$route.params.id,
      insurance_policy_data: null,
      code_options: [],
      broker_options: [],
      isBreakdown: false,
      isLoadingBreakdown: false,
      registry_data: null,
      breakdowns: [],
      insurerHasFee: false,
      fields: [
        {
          key: "title",
          label: "Scorporo",
        },
        {
          key: "net",
          label: "Netto",
        },
        {
          key: "tax",
          label: "Tasse",
        },
        {
          key: "grossRow",
          label: "Lordo Riga",
        },
      ],
      //----Breakdownables
      form: {
        quick_value: "",
        inpt_label: "",
        insurance_ancillary: {
          code: "A",
          //title: null,
          effective_at: null,
          delay_days: null,
          insurance_policy_id: null,
          broker_id: null,
          status_ancillary: null,
          net: 0.0,
          gross: 0.0,
          tax: 0.0,
          fee_net: 0.0,
          fee_tax: 0.0,
          fee_gross: 0.0,
        },
        //Scorpori
        breakdown: {},
      },
    };
  },
  components: {
    BaseIcon,
    BaseSelect,
    BaseInput,
    CustomInputs,
    BaseCurrency,
    BaseDatepicker,
    QuickSearchPolicyModal,
    BaseLabel,
  },
  methods: {
    toInfoData,
    onSubmit() {
      if (this.isBreakdown) {
        this.form[this.rep].net = sumBreakdowns(this.breakdowns, "net");
        this.form[this.rep].gross =
          sumBreakdowns(this.breakdowns, "net") +
          sumBreakdowns(this.breakdowns, "tax");
        this.form[this.rep].tax = sumBreakdowns(this.breakdowns, "tax");
      } else {
        this.form[this.rep].gross =
          this.form[this.rep].net + this.form[this.rep].tax;
      }
      this.isLoading = true;
      this.form[this.rep].delay_until = this.form[this.rep].delay_days
        ? moment(this.form[this.rep].effective_at)
            .add(this.form[this.rep].delay_days, "days")
            .format("YYYY-MM-DD")
        : null;
      this.form[this.rep].collaboration_type =
        this.insurance_policy_data.collaboration_type.value;
      this.form[this.rep].fee_gross = this.grossSumFee;

      this.store(this.repository)
        .then((response) => {
          // console.debug(response);
          let id = response.data.data.id;

          if (this.isBreakdown) {
            for (let i = 0; i < this.breakdowns.length; i++) {
              let breakdownId = this.breakdowns[i].id;
              this.form.breakdown[breakdownId] = {
                net: this.breakdowns[i].net.value,
                tax: this.breakdowns[i].tax.value,
              };
            }
            let stores = this.addBreakdownables(id, "breakdown", {
              breakdown: this.form.breakdown,
            });
            Promise.all([stores])
              .then(() => {
                this.$showSnackbar({
                  preset: "success",
                  text: `Azione Completata: Titolo Creato`,
                });
                this.shortcut(
                  "module.TIT.TINS",
                  null,
                  null,
                  "filterInsuranceAncillaries"
                );
              })
              .catch((error) => {
                let errMsg = this.$getErrorMessage(error);
                this.$showSnackbar({
                  preset: "error",
                  text: `${errMsg}`,
                });
              })
              .finally(() => {
                this.isLoading = false;
              });
          } else {
            this.$showSnackbar({
              preset: "success",
              text: `Azione Completata: Titolo Creato`,
            });
            this.shortcut(
              "module.TIT.TINS",
              null,
              null,
              "filterInsuranceAncillaries"
            );
          }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    addBreakdownables(insuranceAncillaryId, relation, payload) {
      const Repo = RepositoryFactory.get("insurance_ancillary");
      Repo.pivot_store(insuranceAncillaryId, relation, payload);
    },
    handlePolicyModalInput(value) {
      this.form.quick_value = value.id;
      this.form[this.rep].insurance_policy_id = value.id;
      this.form.inpt_label = value.number;
      this.insurance_policy_data = value;
      this.form[this.rep].broker_id = value.broker_id;
      if (value.broker) {
        this.form[this.rep].broker_id =
          value.broker.status_broker.value === 0 ? value.broker_id : null;
      }

      // Set delay_days
      this.form[this.rep].delay_days =
        this.form[this.rep].delay_days === "P" ? 0 : value.delay_days;

      // Fee
      if (value?.insurer?.id) {
        const selectedInsurer = this.getInsurers().find(
          (e) => e.value == value.insurer.id
        );
        if (selectedInsurer && selectedInsurer.use_fee === "N") {
          this.form[this.rep].fee_tax = 0.0;
          this.form[this.rep].fee_net = 0.0;
          this.insurerHasFee = false;
        }
        if (selectedInsurer && selectedInsurer.use_fee === "Y") {
          this.insurerHasFee = true;
        }
      }

      this.isLoadingBreakdown = true;
      const Repo = RepositoryFactory.get("breakdown");
      let querystring = `byInsuranceRisk[id]=${value.insurance_risk_id}`;
      Repo.index(querystring)
        .then((response) => {
          const data = response.data.data;
          if (data.length) {
            this.breakdowns = dataMappingPremium(data);
          } else {
            this.isBreakdown = false;
            this.breakdowns = [];
          }
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        })
        .finally(() => {
          this.isLoadingBreakdown = false;
        });

      this.$bvModal.hide("quickSearchPolicyModal");
    },
    openQuickSearchPolicy() {
      this.form.quick_value = null;
      this.$bvModal.show("quickSearchPolicyModal");
    },
    onClickSwitch() {
      if (!this.form[this.rep].insurance_policy_id) {
        this.$showSnackbar({
          preset: "error",
          text: `Devi selezionare una polizza per abilitare gli scorpori`,
        });
      }
    },
    onChangeSwitch() {
      this.clearPremiumVModel();
    },
    clearPremiumVModel() {
      if (!this.isBreakdown) {
        this.form[this.rep].net = 0.0;
        this.form[this.rep].gross = 0.0;
        this.form[this.rep].tax = 0.0;
      }
    },
    sumBreakdowns(array, field) {
      let initialValue = 0;
      let sum = array.reduce(function (previousValue, currentValue) {
        return previousValue + currentValue[field].value;
      }, initialValue);
      return sum;
    },
    grossRowBookEntry(net, tax) {
      return toLocaleCurrency(net + tax);
    },
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getInsurers: "insurers",
    }),
  },
  created() {
    this.isLoading = true;
    this.fetchCreateForm(this.repository).then(() => {
      this.code_options = this.beForm[this.rep].code.options.filter(
        (el) => el.value === this.form[this.rep].code
      );
      const default_status_ancillary = this.beForm[
        this.rep
      ].status_ancillary.options.find((option) => option.value == 0);
      if (default_status_ancillary)
        this.form[this.rep].status_ancillary = default_status_ancillary.value;
      this.isLoading = false;
    });
  },
  computed: {
    agencyHasFee() {
      return this.$store.state.auth.use_fee === "Y" ? true : false;
    },
    netSum: {
      get() {
        return toLocaleCurrency(sumBreakdowns(this.breakdowns, "net"));
      },
    },
    taxSum: {
      get() {
        return toLocaleCurrency(sumBreakdowns(this.breakdowns, "tax"));
      },
    },
    grossSum: {
      get() {
        return toLocaleCurrency(
          sumBreakdowns(this.breakdowns, "net") +
            sumBreakdowns(this.breakdowns, "tax")
        );
      },
    },
    grossPremiumSum: {
      get() {
        return toLocaleCurrency(
          this.form[this.rep].net + this.form[this.rep].tax
        );
      },
    },
    grossSumFee() {
      return this.form[this.rep].fee_net + this.form[this.rep].fee_tax;
    },
    breakdownable() {
      return "breakdownable";
    },
    rep() {
      return this.repository;
    },
  },
  mounted() {
    this.broker_options = this.getSalesmen();
  },
  watch: {
    "form.inpt_label": {
      deep: true,
      handler() {
        if (!this.form[this.rep].insurance_policy_id) {
          this.insurance_policy_data = null;
        }
      },
    },
  },
};
</script>

<style scoped>
.switch {
  width: 70px;
}
</style>
